import React from "react";

import Header2 from "components/Header/Header2";

const SiteHeader = () => {

  return <Header2 />;
};

export default SiteHeader;
