import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, FC, ChangeEvent } from 'react'
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ContactFormServices from 'services/ContactFormServices';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const serviceId = 'service_mozs0p9';
const templateId = 'template_u9fyp5t';
const publicKey = 'f3J0Fv7DkX-KrgCro';

export interface PageSubcriptionProps {
  className?: string;
  planSelect?: string;
  detailCustomPlan?: string;
  isOpen?: boolean;
  closeModal: () => void;
}

const ModalPlan: FC<PageSubcriptionProps> = ({ isOpen, closeModal, planSelect,detailCustomPlan }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputData = { ...formData, [e.target.name]: e.target.value };
    setFormData(inputData);
  };
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputData = { ...formData, [e.target.name]: e.target.value };
    setFormData(inputData);
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
    })
  }

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let detailCustom = '-';
    if(planSelect == 'Custom'){
      if (typeof detailCustomPlan !== 'undefined') {
        detailCustom = detailCustomPlan;
      }
    }

    if (Object.values(formData).includes('')) {
      toast.error('No se admiten campos en blancos', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
      return;
    }

    const dataEmailJS = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        plan_select: planSelect,
        detail_custom: detailCustom,
        name: formData?.name,
        email: formData?.email,
        message: formData?.message,
        subject:'Contacto por Plan'
      }
    }

    try {
      setLoading(true);

      const res = await axios.post('https://api.emailjs.com/api/v1.0/email/send', dataEmailJS)
      /* const response = await ContactFormServices.sendPlanContactForm(
        planSelect,
        detailCustom,
        formData.name,
        formData.email,
        formData.message,
        'Contacto por Plan',
      ) */

      if (res?.status === 200) {
        toast.success('Solicitud enviada con exito! Un ejecutivo se contactara contigo', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        resetForm();
        setLoading(false);
      } else {
        toast.error('Error al Completar tu Solicitud, Verifique los campos e Intente nuevamente.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          })
        setLoading(false);
      }

      /* if ((await response.success) === 'true') {
        toast.success('Solicitud enviada con exito! Un ejecutivo se contactara contigo', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        resetForm();
        setLoading(false);
      } else {
        toast.error('Error al Completar tu Solicitud, Verifique los campos e Intente nuevamente.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          })
        setLoading(false);
      } */
    } catch (error) {
      setLoading(false);
      console.log(error)
    }

    closeModal();
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 text-gray-900 font-semibold"
                  >
                    Confirmación de Suscripcion
                  </Dialog.Title>
                  <div className='absolute top-3 right-3 cursor-pointer text-gray-900' onClick={closeModal}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                      width="35" height="35" viewBox="0 0 50.000000 50.000000"
                      preserveAspectRatio="xMidYMid meet">

                      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                      fill="currentColor" stroke="none">
                      <path d="M54 455 c3 -8 32 -55 66 -104 33 -50 60 -95 60 -101 0 -6 -27 -51
                      -60 -101 -82 -121 -82 -119 -27 -119 46 0 46 0 98 75 28 41 55 75 59 75 4 0
                      31 -34 59 -75 52 -75 52 -75 98 -75 55 0 55 -2 -27 119 -33 50 -60 95 -60 101
                      0 6 27 51 60 101 82 121 82 119 27 119 -46 0 -46 0 -98 -75 -28 -41 -55 -75
                      -59 -75 -4 0 -31 34 -59 75 -52 75 -52 75 -98 75 -36 0 -44 -3 -39 -15z"/>
                      </g>
                    </svg>
                  </div>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Suscripción seleccionada: <span className='font-bold'>{planSelect}</span>
                    </p>
                    <hr className='my-2'/>
                    <p className="text-sm text-gray-500">
                      Detalle de la suscripcion:
                    </p>
                    <ul className='ml-2 text-sm text-gray-500'>
                      {planSelect !== 'Custom' ? (
                        <>
                          <li>- Landing {planSelect === 'Premium' ? 'o Páginas':''}</li>
                          <li>- Dominio, Hosting, Certificado SSL, Diseño Adaptativo</li>
                          {planSelect === 'Premium' ? (<li>- Posicionamiento web {'(SEO)'}</li>):''}
                        </>
                      ) : (
                        <>
                        {detailCustomPlan?.includes('Landing') ? (<li>- Landing</li>) : ''}
                        {detailCustomPlan?.includes('Páginas') ? (<li>- Páginas</li>) : ''}
                        {detailCustomPlan?.includes('Conjunto') ? (<li>- Dominio, Hosting, Certificado SSL, Diseño Adaptativo</li>) : ''}
                        {detailCustomPlan?.includes('SEO') ? (<li>- Posicionamiento web {'(SEO)'}</li>) : ''}
                        </>
                        
                      )}
                      
                    </ul>
                    <hr className='my-5'/>
                    <form onSubmit={onFormSubmit} className="grid grid-cols-1 gap-6">
                      <label className="block">
                        <label
                          className={`nc-Label text-base font-medium text-neutral-900`}
                          data-nc-id="Label"
                        >
                          Nombre Completo
                        </label>
                        <input
                          placeholder="Aldebaran Toro"
                          type="text"
                          name="name"
                          value={formData?.name}
                          onChange={handleInputChange}
                          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white disabled:bg-neutral-200 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        />
                      </label>
                      <label className="block">
                        <label
                          className={`nc-Label text-base font-medium text-neutral-900`}
                          data-nc-id="Label"
                        >
                          Correo
                        </label>

                        <input
                          placeholder="correo@correo.com"
                          type="email"
                          name="email"
                          value={formData?.email}
                          onChange={handleInputChange}
                          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white disabled:bg-neutral-200 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        />
                      </label>
                      <label className="block">
                        <label
                          className={`nc-Label text-base font-medium text-neutral-900`}
                          data-nc-id="Label"
                        >
                          Mensaje
                        </label>

                        <textarea
                          value={formData?.message} 
                          className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white mt-1`}
                          rows={6}
                          name="message" onChange={handleTextAreaChange}
                        />
                      </label>
                      <div>
                        <ButtonPrimary type="submit">
                        {loading ? 'Enviando...' : 'Enviar'}
                        </ButtonPrimary>
                      </div>
                    </form>
                  </div>

                  {/* <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer pauseOnFocusLoss={false}/>
    </>
  )
}

export default ModalPlan
