import Heading from "components/Heading/Heading";
import { servicesData } from "data/data";
import React, { FC } from "react";
import CardNFTMusic3 from "./CardNFTMusic3";
import CardNFTMusic2 from "./CardNFTMusic2";

export interface SectionMagazine8Props {
  className?: string;
}

function truncateDescription(description: string): string {
  const words = description.split(' ');
  const truncatedWords = words.slice(0, 8);
  const truncatedDescription = truncatedWords.join(' ');

  if (words.length > 8) {
    return `${truncatedDescription}...`;
  }

  return truncatedDescription;
}

const SectionMagazine8: FC<SectionMagazine8Props> = ({ className = "" }) => {
  return (
    <>
    <div className={`nc-SectionMagazine8 relative ${className}`}>
      <Heading
        desc={"Revisa con detalle nuestros servicios de páginas web"}
        className="mb-14 text-neutral-900 dark:text-neutral-50"
      >
        Servicios
      </Heading>
      <div className={`grid grid-cols-1 sm:grid-cols-6 gap-6 2xl:gap-8`}>
        {servicesData.map((p, index) => (
          // Verifica si featured es true
          p.featured ? (
            <CardNFTMusic3
              featuredImage={p.img}
              className="sm:col-span-3 xl:col-span-2"
              titleData={p.title} 
              desData={truncateDescription(p.descrip)}
              key={index}
            />
          ) : ''
        ))}
        {/* <CardNFTMusic
          featuredImage="https://images.unsplash.com/photo-1618556450994-a6a128ef0d9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80"
          className="sm:col-span-3 xl:col-span-2"
        /> */}
        <div className="grid grid-rows-3 grid-cols-1 sm:grid-cols-2 gap-6 xl:gap-8 sm:col-span-6 xl:col-span-4">
          {servicesData.map(
            (p, index) => (
              p.featured ? '':
              (<CardNFTMusic2 titleData={p.title} desData={truncateDescription(p.descrip)}  featuredImage={p.img} key={index} />)
            )
          )}
        </div>
        {/* <div className="grid grid-rows-3 gap-6 xl:gap-8 sm:col-span-6 xl:col-span-2">
          {[nftsAbstracts[0], nftsAbstracts[4], nftsAbstracts[7]].map(
            (p, index) => (
              <CardNFTMusic2 featuredImage={p} key={index} />
            )
          )}
        </div> */}
      </div>
    </div>

<div className="flex justify-center mt-8">


      <a href="#PlanesSection" className={`nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors px-4 py-3 sm:px-6 text-sm sm:text-base font-medium ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50`}>
        <span>Ver Planes</span>
        <span>
          <svg className="w-5 h-5 ml-2.5" viewBox="0 0 24 24" fill="none">
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 22L20 20"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </a>
      </div>
    </>
    
  );
};

export default SectionMagazine8;
