import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

export interface PageContactProps {
    className?: string;
}

const PagePoliticsCookies: FC<PageContactProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageContact overflow-hidden ${className}`}
            data-nc-id="PageContact"
        >
            <Helmet>
                <title>BecauseYes | Políticas de Cookies</title>
                <meta name="description" content="BecauseYes, Bienvenidos a nuestro equipo 
        de programadores altamente calificados y dedicados a llevar tus ideas 
        a la vida digital. Somos un conjunto apasionado de profesionales 
        que combinan conocimientos técnicos avanzados con una creatividad sin límites."/>

                <meta name="keywords" content="porque si, becauseyes, 
        because yes, contacta, contacto, email, whatsApp, sociales, redes,
        correo, mensaje, formulario de contacto, contáctanos." />

                <link rel="canonical" href="https://becauseyes.cl/" />
                <meta name="robots" content="follow" />
                <meta name="author" content="BecauseYes" />
                <meta name="publisher" content="BecauseYes" />
            </Helmet>
            {/* GLASSMOPHIN */}
            <BgGlassmorphism />
            <div className="mb-24 lg:mb-24 px-4 relative z-10">
                <h2 className="my-8 mt-12 flex items-center align-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    <span className="mr-2 lg:mr-4 text-3xl md:text-4xl leading-none">🍪</span>
                    <strong>POLÍTICA DE COOKIES</strong>
                </h2>
                <a className="underline font-semibold text-lg  flex items-center justify-center mb-5" href="https://becauseyes.cl/" rel="noreferrer" target="_blank">https://becauseyes.cl/</a>
                <div className="container max-w-7xl mx-auto">
                    <div className="flex-shrink-0 grid grid-cols-1 gap-12 ">
                        <div className="max-w space-y-4">
                            <p>
                                El acceso a este Sitio Web puede implicar la utilización de cookies. Las cookies son pequeñas cantidades de información que se 
                                almacenan en el navegador utilizado por cada Usuario —en los distintos dispositivos que pueda utilizar para navegar— para que 
                                el servidor recuerde cierta información que posteriormente y únicamente el servidor que la implementó leerá. Las cookies facilitan 
                                la navegación, la hacen más amigable, y no dañan el dispositivo de navegación.</p>
                            <p>
                                Las cookies son procedimientos automáticos de recogida de información relativa a las preferencias 
                                determinadas por el Usuario durante su visita al Sitio Web con el fin de reconocerlo como Usuario, y 
                                personalizar su experiencia y el uso del Sitio Web, y pueden también, por ejemplo, ayudar a identificar y resolver errores.</p>
                            <p>
                                La información recabada a través de las cookies puede incluir la fecha y hora de visitas al Sitio Web, 
                                las páginas visionadas, el tiempo que ha estado en el Sitio Web y los sitios visitados justo antes y 
                                después del mismo. Sin embargo, ninguna cookie permite que esta misma pueda contactarse con el número de 
                                teléfono del Usuario o con cualquier otro medio de contacto personal. Ninguna cookie puede extraer información 
                                del disco duro del Usuario o robar información personal. La única manera de que la información privada del Usuario 
                                forme parte del archivo Cookie es que el usuario dé personalmente esa información al servidor.
                            </p>
                            <p>
                                Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las mismas 
                                les será de aplicación la Política de Privacidad anteriormente descrita. En este sentido, para la utilización 
                                de las mismas será necesario el consentimiento del Usuario. Este consentimiento será comunicado, en base a una 
                                elección auténtica, ofrecido mediante una decisión afirmativa y positiva, antes del tratamiento inicial, removible y documentado.
                            </p>
                            <p className="text-xl lg:text-2xl"><strong> Deshabilitar, rechazar y eliminar cookies</strong></p>
                                  <p> El Usuario puede deshabilitar, rechazar y eliminar las cookies —total o parcialmente— instaladas en su 
                                    dispositivo mediante la configuración de su navegador (entre los que se encuentran, por ejemplo, Chrome, Firefox, Safari, Explorer). 
                                    En este sentido, los procedimientos para rechazar y eliminar las cookies pueden diferir de un navegador de Internet a otro. 
                                    En consecuencia, el Usuario debe acudir a las instrucciones facilitadas por el propio navegador de Internet que esté utilizando. 
                                    En el supuesto de que rechace el uso de cookies —total o parcialmente— podrá seguir usando el Sitio Web, si bien podrá tener limitada
                                    la utilización de algunas de las prestaciones del mismo. Este documento de Política de Cookies ha sido creado mediante el generador 
                                    de plantilla de política de cookies web gratis online el día 16/04/2024..</p>
                        
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PagePoliticsCookies;
