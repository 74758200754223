import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import CardNFT from "components/CardNFT";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";

export interface PageSearchProps {
  className?: string;
}

const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>BecauseYes | Servicios relacionados</title>
        <meta name="description" content="BecauseYes, Bienvenidos a nuestro equipo 
        de programadores altamente calificados y dedicados a llevar tus ideas 
        a la vida digital. Somos un conjunto apasionado de profesionales 
        que combinan conocimientos técnicos avanzados con una creatividad sin límites."/>
        
        <meta name="keywords" content="servicios externos, contador, contabilidad, 
        hardware, mantenimiento, community manager, redes, redes sociales porque si, 
        BecauseYes, Because Yes, becauseyes, because yes, crea tu sitio web, sitio web, 
        customización, servicios, ventajas, planes y suscripciones, mantención web, 
        hosting, certificado ssl, diseño responsive, SEO, posicionamiento SEO,
        cómo funciona, how work, contacta, plan custom, plan standard, plan premium, servicios externos,
        servicio contabilidad, servicio markenting, servicio diseño, descuentos,
        registrate. " />

        <link rel="canonical" href="https://becauseyes.cl/" />
        <meta name="robots" content="follow" />
        <meta name="author" content="BecauseYes"/>
        <meta name="publisher" content="BecauseYes"/>
      </Helmet>
        <header className="text-center max-w-2xl mx-auto mt-20 mb-10" id="PlanesSection">
          <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            <span className="mr-4 text-3xl md:text-4xl leading-none">📬</span>
            Servicios Relacionados
          </h2>
          <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
            Servicios que se adaptan a las necesidades que requieras.
          </span>
        </header>
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* FILTER */}
          <HeaderFilterSearchPage />

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            <CardAuthorBox3/>   
          </div>

          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageSearch;
