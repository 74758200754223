// import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
// import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";
// import CardAuthorBox4 from "components/CardAuthorBox4/CardAuthorBox4";
import HeadingBY from "components/Heading/HeadingBY";

// import NavItem2 from "components/NavItem2";
import React, { FC , useState} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ModalServicesExtern from "components/ModalServicesExtern/ModalServExtern";

export interface SectionGridAuthorBoxProps {
  className?: string;
  sectionStyle?: "style1" | "style2";
  gridClassName?: string;
  boxCard?: "box1" | "box2" | "box3" | "box4";
  data?: any[];
}

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = "py-6 lg:py-10",
  boxCard = "box1",
  sectionStyle = "style1",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3",
  data = Array.from("0"),
}) => {
  const [tabActive, setTabActive] = React.useState("Popular");
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }


  function openModal() {
    setIsOpen(true)
  }

  const renderCard = (index: number) => {
    switch (boxCard) {
      case "box3":
        return <CardAuthorBox3 key={index} />;
      default:
        return <CardAuthorBox3 key={index} />;
    }
  };

  const renderHeading1 = () => {
    return (
      <div className="mb-12 lg:mb-16  flex justify-between flex-col sm:flex-row">
        {/* <Heading
          rightPopoverText="Creators"
          rightPopoverOptions={[
            {
              name: "Creators",
              href: "#",
            },
            {
              name: "Buyers",
              href: "#",
            },
          ]}
          className="text-neutral-900 dark:text-neutral-50"
        >
          Popular
        </Heading> */}
        <HeadingBY
          rightPopoverText="Creators"
          className="text-neutral-900 dark:text-neutral-50"
        >
          Servicios relacionados
        </HeadingBY>
        {/* <div className="mt-4 sm:mt-0">
          <SortOrderFilter />
        </div> */}
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionGridAuthorBox relative ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <BackgroundSection />
      {sectionStyle === "style1" ? renderHeading1() : ' '}
      <div className={`grid gap-4 md:gap-7  ${gridClassName}`}>
        {data.length !== 0 ? data.map((index) => renderCard(index)) : (
        <React.Fragment>No se encuentran servicios relacionados</React.Fragment>
         )}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row items-center justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        <ButtonSecondary href="/external-services">Ver más </ButtonSecondary>
        <ButtonPrimary onClick={() => openModal()}>Unete a Nosotros</ButtonPrimary>
      </div>
      <ModalServicesExtern isOpen={isOpen} closeModal={closeModal}></ModalServicesExtern>
    </div>
  );
};

export default SectionGridAuthorBox;
