import rightImg from "images/about/pexels-fauxels-3184291.webp";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
/* import SectionClientSay from "components/SectionClientSay/SectionClientSay"; */

import { AboutData } from "data/data";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>BecauseYes | Equipo </title>
        <meta name="description" content="BecauseYes, Bienvenidos a nuestro equipo 
        de programadores altamente calificados y dedicados a llevar tus ideas 
        a la vida digital. Somos un conjunto apasionado de profesionales 
        que combinan conocimientos técnicos avanzados con una creatividad sin límites."/>
        
        <meta name="keywords" content="porque si, becauseyes, 
        because yes, Sobre nosotros, nosotros, bienvenido, programadores calificados,
        soluciones digitales, contáctanos, equipo, team, fundadores, miembros del equipo,
        informática, formación academica, desarrollador web, co-fundador, director ejecutivo,
        ingeniero informatico, redes, linkedin, instagram, github" />

        <link rel="canonical" href="https://becauseyes.cl/" />
        <meta name="robots" content="follow" />
        <meta name="author" content="BecauseYes"/>
        <meta name="publisher" content="BecauseYes"/>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28 ">
        <SectionHero
          rightImg={rightImg}
          heading={AboutData.main.title}
          btnText="Contáctanos"
          subHeading={AboutData.main.description}
        />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionFounder />
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
