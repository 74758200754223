import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import { nftsAbstracts, personNames, ServiceExtern,} from "contains/fakeData";
import VerifyIcon from "components/VerifyIcon";
import FollowButton from "components/FollowButton";
import ContactButton from "components/Contactar";

import port1 from 'images/servicesExterns/port1.png'
import port2 from 'images/servicesExterns/port2.png'
import port3 from 'images/servicesExterns/port3.png'


import logoEvo from 'images/servicesExterns/evo/logo1.webp'
import port1evo from 'images/servicesExterns/evo/port1.webp'
import port2evo from 'images/servicesExterns/evo/port2.webp'
import port3evo from 'images/servicesExterns/evo/port3.webp'

import logoLoud from 'images/servicesExterns/loud/Logo LOUD.png'
import port1loud from 'images/servicesExterns/loud/port1.webp'
import port2loud from 'images/servicesExterns/loud/port2.webp'
import port3loud from 'images/servicesExterns/loud/port3.webp'

export const servicesExtern: ServiceExtern[] = [
    {
      id:1,
      img:'https://cyvm.cl/static/media/logoVero.c9f9aa560113a630e144.png',
      portada:[
        {
          id:1,
          name: port1,
        },
        {
          id:2,
          name: port2,
        },
        {
          id:3,
          name: port3,
        }
      ],
      author:'Veronica Montenegro',
      authorTwo:'',
      authorThree:'',
      emp:'CYVM',
      description:'CYVM se inicia en el área de Servicios Contables y Tributarios desde 2015, posicionandose como líder en el mercado por su relación precio-calidad.',
      pathPage:'https://cyvm.cl/',
      contact:'953703029',
    },  
    {
      id:2,
      img:logoEvo,
      portada:[
        {
          id:1,
          name: port1evo,
        },
        {
          id:2,
          name: port2evo,
        },
        {
          id:3,
          name: port3evo,
        }
      ],
      author:'Leonardo Lopez',
      authorTwo:'Leonardo Lopez',
      authorThree:'',
      emp:'EVO PC',
      description:'Empresa dedicada a la venta de computadores y portátiles, con mas de 2 años de experiencia en el mercado, contamos con una amplia gama de servicios, como asesorías, mantenimiento de equipos, atenciones a domicilio, armado de computadores personalizados.',
      pathPage:'https://www.instagram.com/evopc.cl/',
      contact:'56930120082',
    },
    {
      id:3,
      img:logoLoud,
      portada:[
        {
          id:1,
          name: port1loud,
        },
        {
          id:2,
          name: port2loud,
        },
        {
          id:3,
          name: port3loud,
        }
      ],
      author:`Luciano Casanova G.`,
      authorTwo:'Tamara Soto P.',
      authorThree:'Francisco González C.',
      emp:'Agencia LOUD',
      description:'Marketing digital, eventos y BTL.',
      pathPage:'https://instagram.com/loudchile',
      contact:'',
    },  
  ]

export interface CardAuthorBox3Props {
  className?: string;
  contact?: boolean;
}

const CardAuthorBox3: FC<CardAuthorBox3Props> = ({
  className = "",
  contact,
}) => {
    return(
        <>
        {servicesExtern?.length !== 0 ? servicesExtern.map((item, id) => {
                return(
                <div key={id}
                    className={`nc-CardAuthorBox3 relative flex flex-col p-4 overflow-hidden [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
                    data-nc-id="CardAuthorBox3"
                >
                    <div className="relative flex-shrink-0 flex space-x-2 h-24">
                    <NcImage
                        containerClassName="flex flex-grow h-full rounded-xl overflow-hidden"
                    //   src={nftsAbstracts[Math.floor(Math.random() * nftsAbstracts.length)]}
                        src={item.portada[0].name}
                    
                    />
                    <NcImage
                        containerClassName="flex h-full w-24 flex-shrink-0 rounded-xl overflow-hidden"
                        // src={nftsAbstracts[Math.floor(Math.random() * nftsAbstracts.length)]}
                        src={item.portada[1].name}         
                    />
                    <NcImage
                        containerClassName="flex flex-grow h-full rounded-xl overflow-hidden"
                        // src={nftsAbstracts[Math.floor(Math.random() * nftsAbstracts.length)]}
                        src={item.portada[2].name}
                    
                    />
                    </div>
            
                    <div className="-mt-6">
                    <div className="text-center ">
                        <Avatar
                        containerClassName="ring-4 ring-white dark:ring-black !shadow-xl bg-black "
                        sizeClass="w-16 h-16 xl:w-20 xl:h-20 2xl:w-24 2xl:h-24 text-2xl"
                        radius="rounded-full"
                        imgUrl={item.img}
                        />
                    </div>
                    <div className="mt-2.5 flex items-start justify-between">                 
                            {/* {personNames[Math.floor(Math.random() * personNames.length)]} */}
                                  <div>
                                    <h2 className={`text-base font-medium flex items-center`}> 
                                    <span key={id} className="">
                                    {item.emp}    
                                    </span>
                                    <VerifyIcon />
                                    </h2>
                                    <span
                                    className={`block mt-0.5 text-sm text-neutral-500 dark:text-neutral-400`}
                                    >
                                    {item.author}
                                    </span>
                                    <span
                                    className={`block mt-0.5 text-sm text-neutral-500 dark:text-neutral-400`}
                                    >
                                    {item.authorTwo}
                                    </span>
                                    <span
                                    className={`block mt-0.5 text-sm text-neutral-500 dark:text-neutral-400`}
                                    >
                                    {item.authorThree}
                                    </span>
                                </div>
            
                        <ContactButton isContact={!contact} btnContact={item.contact} />
                    </div>
                    <div className="mt-4 text-sm text-neutral-500 dark:text-neutral-400">
                        {item?.description}
                    </div>
                    </div>
            
                    <a href={item?.pathPage} target="_blank" rel="noreferrer" className="absolute inset-0"></a>
                </div>
                )
            
            }): null}
    </>
)
};

export default CardAuthorBox3;
