import React, { FC, useState, ChangeEvent } from "react";
import { Helmet } from "react-helmet";
// import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ContactFormServices from "services/ContactFormServices";
import { ToastContainer, toast } from 'react-toastify';
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import axios from "axios";

export interface PageContactProps {
  className?: string;
}

const serviceId = 'service_mozs0p9';
const templateId = 'template_u9fyp5t';
const publicKey = 'f3J0Fv7DkX-KrgCro';

const info = [
  {
    title: "Dirección",
    desc: "Av. Apoquindo 7935 Oficina 410 torre B",
  },
  {
    title: "Email",
    desc: "21becauseyes@gmail.com",
  },
  {
    title: "WhatsApp",
    desc: "+56 9 53858376",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputData = { ...formData, [e.target.name]: e.target.value };
    setFormData(inputData);
  };
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputData = { ...formData, [e.target.name]: e.target.value };
    setFormData(inputData);
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
    })
  }

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (Object.values(formData).includes('')) {
      toast.error('No se admiten campos en blancos', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
      return;
    }

    const dataEmailJS = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        plan_select: '',
        detail_custom: '',
        name: formData?.name,
        email: formData?.email,
        message: formData?.message,
        subject:'Contacto'
      }
    }
    try {
      setLoading(true);
      const res = await axios.post('https://api.emailjs.com/api/v1.0/email/send', dataEmailJS)
      if (res?.status === 200) {
        toast.success('Solicitud enviada con exito! Un ejecutivo se contactara contigo', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        resetForm();
        setLoading(false);
      } else {
        toast.error('Error al Completar tu Solicitud, Verifique los campos e Intente nuevamente.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          })
        setLoading(false);
      }


      /* const response = await ContactFormServices.sendContactForm(
        formData.name,
        formData.email,
        formData.message,
        'Contacto',
      )

      if ((await response.success) === 'true') {
        toast.success('Solicitud enviada con exito! Un ejecutivo se contactara contigo', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        resetForm();
        setLoading(false);
      } else {
        toast.error('Error al Completar tu Solicitud, Verifique los campos e Intente nuevamente.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          })
        setLoading(false);
      } */
      
    } catch (error) {
        setLoading(false);
    }
  }

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>BecauseYes | Contacto</title>
        <meta name="description" content="BecauseYes, Bienvenidos a nuestro equipo 
        de programadores altamente calificados y dedicados a llevar tus ideas 
        a la vida digital. Somos un conjunto apasionado de profesionales 
        que combinan conocimientos técnicos avanzados con una creatividad sin límites."/>
        
        <meta name="keywords" content="porque si, becauseyes, 
        because yes, contacta, contacto, email, whatsApp, sociales, redes,
        correo, mensaje, formulario de contacto, contáctanos." />

        <link rel="canonical" href="https://becauseyes.cl/" />
        <meta name="robots" content="follow" />
        <meta name="author" content="BecauseYes"/>
        <meta name="publisher" content="BecauseYes"/>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      <div className="mb-24 lg:mb-32 relative z-10">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contacto
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              {/*<div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                   Sociales
                </h3>
                 <SocialsList className="mt-2" /> 
              </div>*/}
            </div>
            <div>
              <form onSubmit={onFormSubmit} className="grid grid-cols-1 gap-6">
                <label className="block">
                  <Label>Nombre Completo</Label>

                  <Input
                    placeholder="Aldebaran Toro"
                    type="text"
                    className="mt-1"
                    name="name"
                    value={formData?.name}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="block">
                  <Label>Correo</Label>

                  <Input
                    type="email"
                    placeholder="correo@correo.com"
                    className="mt-1"
                    name="email"
                    value={formData?.email}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="block">
                  <Label>Mensaje</Label>

                  <Textarea 
                    className="mt-1" 
                    value={formData?.message} 
                    rows={6} 
                    name="message" 
                    onChange={handleTextAreaChange} 
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit">
                  {loading ? 'Enviando...' : 'Enviar'}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer pauseOnFocusLoss={false}/>
      </div>
    </div>
  );
};

export default PageContact;
