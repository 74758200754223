import React, { FC } from "react";
import hero1 from "images/heroImg/codingOutline.webp";
import hero2 from "images/heroImg/landingPageTwoColor.webp";
import hero3 from "images/heroImg/uiDesignOutline.webp";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHero2Props {
  children?: React.ReactNode;
  className?: string;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  return (
    <div
      className={`nc-SectionHero2 flex flex-col-reverse lg:flex-col relative ${className}`}
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-36 xl:pb-60 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-semibold text-4xl md:text-5xl xl:text-6xl !leading-[114%] ">
            Crea tu sitio web con nosotros.
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          Atrevete y descubre toda las posibilidades de customización web que tenemos para ti.
          </span>
          <a className="disabled:bg-opacity-70 bg-primary-700 hover:bg-primary-500 text-neutral-50 rounded-full p-2 px-4 font-semibold" href="#ServicesSection" >
            <span>Ver Servicios</span>
          </a>
        </div>
        <div className="flex-grow  xl:-mt-20">
          <div className="grid grid-flow-dense grid-cols-1 xl:grid-cols-3 xl:mx-0 2xl:mx-1"> 
            <div className="col-span-1 mx-20 xl:mx-0">
                <article className="p-2 col-span-2 w-[200px] h-[160px] bg-slate-100 shadow-md dark:bg-neutral-800 rounded-2xl m-2  animate-bounceSecond xl:animate-none xl:hover:scale-105 duration-500">
                  <img className="w-full" src={hero2} alt="hero2" loading="lazy" />
                </article>
                <article className="p-2 col-span-2 w-[200px] h-[150px] bg-slate-100 shadow-md dark:bg-neutral-800 rounded-2xl m-2 animate-bounceSecond xl:animate-none xl:hover:scale-105 duration-500">
                  <img className="w-full" src={hero3} alt="hero3" loading="lazy" />
                </article>
            </div>
            <article className="hidden xl:block col-span-2 mx-16 xl:mx-4 2xl:mx-2 w-[250px] h-[200px] bg-slate-100 shadow-md dark:bg-neutral-800 rounded-2xl xl:mt-16 animate-bounceSecond xl:animate-none xl:hover:scale-105 duration-500">
                <img className=" w-full" src={hero1} alt="hero1" loading="lazy" />  
            </article>
          </div>
        </div>
      </div>
      <div className="z-10 mb-12 lg:mb-0 lg:-mt-20 xl:-mt-48 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero2;
