import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import Checkbox from "shared/Checkbox/Checkbox";

// DEMO DATA
const forWhat = [
  {
    name: "Singularidad",
    description: "Contar con una página web propia, otorga formalidad y seguridad al cliente",
    checked: true,
  },
  {
    name: "Posicionamiento",
    description: "Con un excelente posicionamiento podrá aparecer en las búsquedas de Google",
    checked: true,
  },
  {
    name: "Desarrolla tu imagen",
    description: "Trabaje en el cuidado de su marca, para lograr que visibilidad.",
    checked: true,
  },
  {
    name: "Incrementa tu valoración",
    description: "Genere cierta valoración por parte de los usuarios.",
    checked: true,
  },
  {
    name: "Aumenta tus ventas",
    description: "Existen métodos para transacciones fáciles, rápidas y seguras",
    checked: true,
  },
  {
    name: "Relación activa entre la empresa y los clientes",
    description: "Ofrece una mejor atención a sus clientes, porque podrá responder consultas de forma rápida y dirigida",
    checked: true,
  },
];

export interface ItemTypeSelectProps {
  onChange?: (data: any) => void;
  fieldClassName?: string;
}

const ItemTypeSelect: FC<ItemTypeSelectProps> = ({
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  return (
    <Popover className="flex relative flex-1">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex text-left flex-1 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? "nc-hero-field-focused" : ""
            }`}
            // PHẦN LOCATION-INPUT KHÔNG BẮT ĐƯỢC EVEN-CLICK KHI CLICK VÀO NÚT NÀY, NÊN CẦN CHẠY HACK NÀY
            onClick={() => {
              if (window.innerWidth >= 1024) {
                document.querySelector("html")?.click();
              }
            }}
          >
            <div className="text-neutral-300 dark:text-neutral-400">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.67004 18.9501L7.60004 15.6401C8.39004 15.1101 9.53004 15.1701 10.24 15.7801L10.57 16.0701C11.35 16.7401 12.61 16.7401 13.39 16.0701L17.55 12.5001C18.33 11.8301 19.59 11.8301 20.37 12.5001L22 13.9001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold min-w-[130px] text-primary-700 dark:text-neutral-200">
                Ventajas de un sitio web
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                Principales puntos
              </span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-30 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="">
                <div className="relative flex flex-col space-y-5">
                  {forWhat.map((item) => (
                    <div key={item.name} className="">
                      <span className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="text-primary-6000 dark:text-neutral-200 w-5 h-5 mr-1 "><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                        <h3 className="font-semibold text-md text-primary-6000 dark:text-neutral-200">{item.name}</h3>
                      </span>    
                      <span className="font-light text-sm ">{item.description}</span>
                      {/* <Checkbox
                        name={item.name}
                        label={item.name}
                        subLabel={item.description}
                        defaultChecked={item.checked}
                        
                      /> */}
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ItemTypeSelect;
