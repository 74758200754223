import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";

import VectorImg from "images/VectorHIW.svg";
import Badge from "shared/Badge/Badge";
import Heading from "components/Heading/Heading";
import { HowItWork_Data } from "data/data";

export interface SectionHowItWorkProps {
  className?: string;
  data?: typeof HowItWork_Data[0][];
}



const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = HowItWork_Data,
}) => {
  return (

    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
    <Heading
    desc={"Revisa con detalle nuestros servicios de páginas web"}
    className="mb-14 text-neutral-900 dark:text-neutral-50"
      >
    ¿Cómo funciona?
    </Heading>
      <div className="relative grid sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-20">
        <img
          className="hidden md:block absolute inset-x-0 -top-1"
          src={VectorImg}
          alt="How work"
          loading="lazy"
        />
        {data.map((item: typeof HowItWork_Data[number], index: number) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-5 sm:mb-10 lg:mb-20 max-w-[200px] w-[100px] lg:w-[120px] mx-auto"
              src={item.img}
              className="animate-bounce hover:animate-bounceStop"
              loading="lazy"
            />
            <div className="text-center mt-auto space-y-5">
              <Badge
                name={`Paso ${index + 1}`}
                color={
                  !index
                    ? "blue"
                    : index === 1
                    ? "pink"
                    : index === 2
                    ? "yellow"
                    : "green"
                }
              />
              <h3 className="text-lg font-semibold">{item.title}</h3>
              <span className="block text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
