import { CheckIcon } from "@heroicons/react/solid";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import Checkbox from "shared/Checkbox/Checkbox";

import { pricingsServices } from "data/data";
import { PricingItem } from "data/data";
import SectionServices1 from "components/SectionServices/SectionServices1";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import PageSubcription2 from "./PageSubcription2";

export interface PageSubcriptionProps {
  className?: string;
}

const valueItems = {
  landing:60000,
  navbar:50000,
  dominio:45000,
  hosting:40000,
  ssl:35000,
  ceo:80000,
};

const parseToCLPCurrency = (number:number) => {
  return new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  }).format(number);
};

const PageSubcription: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const [total, setTotal] = useState(0);

  const handleCheckboxChange = (checkboxName: string, value: boolean) => {
    switch (checkboxName) {
      case 'Landing':
        setTotal((prevTotal) => (value ? prevTotal + valueItems.landing : prevTotal - valueItems.landing));
        break;
      case 'Navbar':
        setTotal((prevTotal) => (value ? prevTotal + valueItems.navbar : prevTotal - valueItems.navbar));
        break;
      case 'Dominio':
        setTotal((prevTotal) => (value ? prevTotal + valueItems.dominio : prevTotal - valueItems.dominio));
        break;

      case 'Hosting':
        setTotal((prevTotal) => (value ? prevTotal + valueItems.hosting : prevTotal - valueItems.hosting));
        break;

      case 'SSL':
        setTotal((prevTotal) => (value ? prevTotal + valueItems.ssl : prevTotal - valueItems.ssl));
        break;
      case 'CEO':
        setTotal((prevTotal) => (value ? prevTotal + valueItems.ceo : prevTotal - valueItems.ceo));
        break;
      default:
        break;
    }
  };


  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
          pricing.isPopular
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {pricing.isPopular && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center">
            <span>{pricing.pricing}</span>
            <span className="text-base ml-1 font-normal text-neutral-500">
              {pricing.per}
            </span>
          </h2>
          <p className="text-center mt-1">Luego {pricing.pricing2} /mes</p>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          {pricing.isPopular ? (
            <ButtonPrimary>Seleccionar</ButtonPrimary>
          ) : (
            <ButtonSecondary>
              <span className="font-medium">Seleccionar</span>
            </ButtonSecondary>
          )}
          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className=" py-11 lg:py-1 rounded-xl" id="PlanSection">
          {/* SECCIÓN DE PLANES Y SUSCRIBCIONES */}
          <PageSubcription2/>
      </div>
      

      
    </>
  );
};

export default PageSubcription;
