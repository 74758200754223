import axios from 'axios';
import { realtorData } from 'data/realtor';

const ContactFormServices = {
  sendContactForm: async (
    name,
    email,
    message,
    subjectEmail
  ) => {

    const response = await axios.post(
      `https://formsubmit.co/ajax/${realtorData.email}`,
      {
        Nombre: name,
        Correo: email,
        Mensaje: message,
        '_template': 'table',
        '_subject': subjectEmail,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );

    return response.data;
  },
  sendPlanContactForm: async (
    plan,
    custom,
    name,
    email,
    message,
    subjectEmail
  ) => {

    const response = await axios.post(
      `https://formsubmit.co/ajax/${realtorData.email}`,
      {
        Nombre: name,
        Correo: email,
        Mensaje: message,
        Plan: plan,
        custom: custom,
        '_template': 'table',
        '_subject': subjectEmail,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );

    return response.data;
  },
  sendDiscountForm: async (
    email,
    message,
    subjectEmail
  ) => {

    const response = await axios.post(
      `https://formsubmit.co/ajax/${realtorData.email}`,
      {
        Correo: email,
        Mensaje: message,
        '_template': 'table',
        '_subject': subjectEmail,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );

    return response.data;
  },

  sendServiceExtern: async (
    name,
    email,
    type_service,
    message,
    subjectEmail
  ) => {
    const response = await axios.post(`https://formsubmit.co/ajax/${realtorData.email}`,
    {
      Nombre: name,
      Correo : email,
      'Tipo de Servicio' : type_service,
      Mensaje: message,
      '_template': 'table',
      '_subject': subjectEmail,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
    );
    return response.data;
  }
}


export default ContactFormServices;
