import React, { FC} from "react";
import ButtonPrimary, { ButtonPrimaryProps } from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface FollowButtonProps extends ButtonPrimaryProps {
  isContact?: boolean;
  btnContact?:number | string
}



const ContactButton: FC<FollowButtonProps> = ({
  className = "relative z-10",
  sizeClass = "px-4 py-1.5 min-w-[84px]",
  fontSize = "text-sm font-medium",
  isContact = false,
  btnContact

}) => {
  const [contact, setContact] = React.useState(isContact);
  // const phoneNumber = '953703029';
  const whatsAppBussinessLink = `https://wa.me/${btnContact}`;

  return !contact ? (
    <ButtonPrimary
      className={className}
      sizeClass={sizeClass}
      fontSize={fontSize}
      onClick={() => setContact(true)
    }
    >
      Contactado
    </ButtonPrimary>
  ) : (
    <ButtonSecondary
      className={className}
      sizeClass={sizeClass}
      fontSize={fontSize}
      onClick={() => setContact(false)}
    >
      <a href={whatsAppBussinessLink} target="_blank" rel="noreferrer">
      Contactar
      </a>
    </ButtonSecondary>
  );
};

export default ContactButton;
