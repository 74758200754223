import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";
import { AboutData } from "data/data";
import { IoLogoInstagram, IoLogoGithub,IoLogoLinkedin   } from "react-icons/io5";


export interface People {
  id: string;
  name: string;
  job: string;
  avatar?: string;
  hrefInst?:string,
  hrefLink?:string,
  hrefGit?:string,
}

const FOUNDER_DEMO: People[] = AboutData.founders.profile;

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc={AboutData.founders.description}
      >
        {AboutData.founders.title}
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-2 xl:gap-x-16">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400 mb-2">
              {item.job}
            </span>
            <hr></hr>
            <div className="relative">
              <ul className="flex justify-center">
                <li className="m-4 text-xl xl:text-2xl hover:scale-125 duration-200"><a target="_blank" rel="noreferrer" href={item?.hrefInst || ''}><IoLogoInstagram/></a></li>
                <li className="m-4 text-xl xl:text-2xl hover:scale-125 duration-200"><a target="_blank" rel="noreferrer" href={item.hrefGit}><IoLogoGithub/></a></li>
                <li className="m-4 text-xl xl:text-2xl hover:scale-125 duration-200"><a target="_blank" rel="noreferrer" href={item.hrefLink}><IoLogoLinkedin/></a></li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
