import React from "react";
import { Link } from "react-router-dom";
import { CiStar } from "react-icons/ci";

export interface LogoProps {

  className?: string;
}

const Logo: React.FC<LogoProps> = ({

  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 hover:scale-110 duration-300 ${className}`}
    >
      <div className="relative">
        {/* <img src={chileanFlag} className="absolute -top-6 left-28 w-14 h-14" alt="bandera chilena"/> */}
        <h1 className="flex flex-row items-center text-4xl text-secondary-700 dark:text-primary-600 drop-shadow-sm">
          Bec<span className="dark:text-gray-50 text-secondary-700 bg-transparent"><CiStar/></span>use
          <b className="font-bold text-primary-700 dark:text-secondary-600">Yes</b>
        </h1>
      </div>
    </Link>
    )
  

};

export default Logo;
