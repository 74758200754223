import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import { FC } from "react";
import PropertyTypeSelect from "./PropertyTypeSelect";
import PriceRangeInput from "./PriceRangeInput";
import ItemTypeSelect from "./ItemTypeSelect";

export interface NftSearchFormProps {
  haveDefaultValue?: boolean;
}

// DEFAULT DATA FOR ARCHIVE PAGE
// const defaultLocationValue = "Santiago, Chile";

const NftSearchForm: FC<NftSearchFormProps> = ({
  haveDefaultValue = false,
}) => {
  // const [locationInputValue, setLocationInputValue] = useState("");

  //
  // useEffect(() => {
  //   if (haveDefaultValue) {
  //     setLocationInputValue(defaultLocationValue);
  //   }
  // }, [haveDefaultValue]);
  //

  const renderForm = () => {
    return (
      <form className="w-full relative xl:mt-8 flex flex-col lg:flex-row rounded-[30px] md:rounded-[36px] lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0">

        <ItemTypeSelect />
        <PropertyTypeSelect />

      </form>
    );
  };

  return renderForm();
};

export default NftSearchForm;
