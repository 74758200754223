import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, FC, ChangeEvent } from 'react'
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ContactFormServices from 'services/ContactFormServices';
import { ToastContainer, toast } from 'react-toastify';

export interface PageSubcriptionProps {
  className?: string;
  planSelect?: string;
  detailCustomPlan?: string;
  isOpen?: boolean;
  closeModal: () => void;
}

const ModalServicesExtern: FC<PageSubcriptionProps> = ({ isOpen, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    type_service: '',
    message: '',
  });
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputData = { ...formData, [e.target.name]: e.target.value };
    setFormData(inputData);
  };
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputData = { ...formData, [e.target.name]: e.target.value };
    setFormData(inputData);
  };

  const resetForm = () => {
    setFormData({
        name: '',
        email: '',
        type_service:'', 
        message: '',
    })
  }

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (Object.values(formData).includes('')) {
      toast.error('No se admiten campos en blancos', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
      return;
    }
    try {
      setLoading(true);
      const response = await ContactFormServices.sendServiceExtern(
        formData.name,
        formData.email,
        formData.type_service,
        formData.message,
        'Quiero unirme a los servicios externos',
      )

      if ((await response.success) === 'true') {
        toast.success('Solicitud enviada con exito! nos contactarremos contigo', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        resetForm();
        setLoading(false);
      } else {
        toast.error('Error al Completar tu Solicitud, Verifique los campos e Intente nuevamente.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          })
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
    }

    closeModal();
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 text-gray-900 font-semibold"
                  >
                    Confirma tu solicitud 
                  </Dialog.Title>
                  <div className='absolute top-3 right-3 cursor-pointer text-gray-900' onClick={closeModal}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                      width="35" height="35" viewBox="0 0 50.000000 50.000000"
                      preserveAspectRatio="xMidYMid meet">

                      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                      fill="currentColor" stroke="none">
                      <path d="M54 455 c3 -8 32 -55 66 -104 33 -50 60 -95 60 -101 0 -6 -27 -51
                      -60 -101 -82 -121 -82 -119 -27 -119 46 0 46 0 98 75 28 41 55 75 59 75 4 0
                      31 -34 59 -75 52 -75 52 -75 98 -75 55 0 55 -2 -27 119 -33 50 -60 95 -60 101
                      0 6 27 51 60 101 82 121 82 119 27 119 -46 0 -46 0 -98 -75 -28 -41 -55 -75
                      -59 -75 -4 0 -31 34 -59 75 -52 75 -52 75 -98 75 -36 0 -44 -3 -39 -15z"/>
                      </g>
                    </svg>
                  </div>
                  <div className="mt-2">
                    <hr className='my-5'/>
                    <form onSubmit={onFormSubmit} className="grid grid-cols-1 gap-6">
                      <label className="block">
                        <label
                          className={`nc-Label text-base font-medium text-neutral-900`}
                          data-nc-id="Label"
                        >
                          Nombre Completo
                        </label>
                        <input
                          placeholder="Aldebaran Toro"
                          type="text"
                          name="name"
                          value={formData?.name}
                          onChange={handleInputChange}
                          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white disabled:bg-neutral-200 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        />
                      </label>
                      <label className="block">
                        <label
                          className={`nc-Label text-base font-medium text-neutral-900`}
                          data-nc-id="Label"
                        >
                          Correo
                        </label>

                        <input
                          placeholder="correo@correo.com"
                          type="email"
                          name="email"
                          value={formData?.email}
                          onChange={handleInputChange}
                          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white disabled:bg-neutral-200 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        />
                      </label>
                      <label className="block">
                        <label
                          className={`nc-Label text-base font-medium text-neutral-900`}
                          data-nc-id="Label"
                        >
                            Qué servicio ofreces 
                        </label>

                        <input
                          placeholder="Contabilidad, Diseño, Marketing, etc."
                          type="text"
                          name="type_service"
                          value={formData?.type_service}
                          onChange={handleInputChange}
                          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white disabled:bg-neutral-200 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        />
                      </label>
                      <label className="block">
                        <label
                          className={`nc-Label text-base font-medium text-neutral-900`}
                          data-nc-id="Label"
                        >
                          Mensaje
                        </label>

                        <textarea
                          value={formData?.message} 
                          className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white mt-1`}
                          rows={6}
                          name="message" onChange={handleTextAreaChange}
                          placeholder='Describenos brevemente tu servicio...'
                        />
                      </label>
                      <div>
                        <ButtonPrimary type="submit">
                        {loading ? 'Enviando...' : 'Enviar'}
                        </ButtonPrimary>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer pauseOnFocusLoss={false}/>
    </>
  )
}

export default ModalServicesExtern
