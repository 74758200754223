import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";


export const NAVIGATION_DEMO_2: NavItemType[] = [

  {
    id: ncNanoId(),
    href: "/",
    name: "Inicio",
  },
  {
    id: ncNanoId(),
    href: "/clientes",
    name: "Clientes",
  },
  {
    id: ncNanoId(),
    href: "/planes",
    name: "Planes",
  },
  {
    id: ncNanoId(),
    href: "/external-services",
    name: "Servicios relacionados",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Nosotros",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contacto",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/politics-private",
  //   name: "politics private",
  // },

];
