// import Logo from "shared/Logo/Logo";
import { CustomLink } from "data/types";
import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { IoLogoInstagram, IoLogoGithub,IoLogoLinkedin   } from "react-icons/io5";
import { FcBusinessman } from "react-icons/fc";
import getCurrentYear from "utils/getCurrentYear";
import { NavItemType } from "../../shared/Navigation/NavigationItem";
import { CiStar } from "react-icons/ci";

const Socials = [
  {
    id:1,
    name: "BecauseYes",
    description: "Contar con una página web propia, otorga formalidad y seguridad al cliente",
    hrefInst:'https://www.instagram.com/_becauseyes?igsh=NHZrZHdoaWVnbHR4',
    hrefGit:'https://github.com/',
    hrefLink:'https://www.linkedin.com/in/',
    icon:<FcBusinessman />
  },
  // {
  //   id:2,
  //   name: "Nicolás Cabrera Millacan",
  //   description: "Con un excelente posicionamiento podrá aparecer en las búsquedas de Google",
  //   hrefInst:'',
  //   hrefGit:'https://github.com/jaackx1',
  //   hrefLink:'https://www.linkedin.com/in/nicolas-cabrera-m',
  //   icon:<FcBusinessman />

  // },
];



export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Menu",
    menus: [
      { href: "#HeroSection", label: "Inicio" },
      { href: "/about", label: "Nosotros" },
      { href: "/contact", label: "Contáctanos" },
    ],
  },
  {
    id: "2",
    title: "Secciones",
    menus: [
      { href: "/", label: "Inicio" },
      { href: "#ServicesSection", label: "Servicios" },
      { href: "#HowWorkSection", label: "Cómo funciona" },
      { href: "/planes", label: "Plan y suscripciones" },
      { href: "#DiscountSection", label: "Obtén tu descuento" },
    ],
  },
  {
    id: "1",
    title: "Políticas y cookies",
    menus: [
      { href: "/politicas-de-privacidad", label: "Política de Privacidad" },
      { href: "/politicas-de-cookies", label: "Politicas de Cookies" },

    ],
  },

];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-md">
        <h2 className="font-semibold text-lg text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a 
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-20 lg:pt-32 lg:pb-14 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-2 md:grid-rever 2xl:grid-cols-4 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-3 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1 text-start">
          {/* <Link
            to="/"
            className={`ttnc-logo inline-block text-primary-6000 hover:scale-110 duration-300`}
          >
            <h1 className="text-6xl 2xl:text-4xl dark:text-gray-50 ">
              Because<b className="font-bold">Yes</b>
            </h1>
            <div>
              <small className="dark:text-gray-50" >Atrévete con Nosotros</small>
            </div>
          </Link> */}

        <Link
              to="/"
              className={`ttnc-logo inline-block text-primary-6000 hover:scale-110 duration-300`}
            >
              <div className="relative">
                {/* <img src={chileanFlag} className="absolute -top-6 left-28 w-14 h-14" alt="bandera chilena"/> */}
                <h1 className="flex flex-row items-center text-4xl text-secondary-700 dark:text-primary-600 drop-shadow-sm">
                  Bec<span className="dark:text-gray-50 text-secondary-700 bg-transparent"><CiStar/></span>use
                  <b className="font-bold text-primary-700 dark:text-secondary-600">Yes</b>
                </h1>
                <div>
                  <small className="dark:text-gray-50" >Atrévete con Nosotros</small>
                </div>
              </div>
            </Link>
          
            <div className="my-3 lg:my-6 text-center lg:text-start">
                <ul className="flex justify-start gap-1 text-sm">
                  <li>
                   {/* <NcDropDown /> */}
                   <Popover className="flex relative flex-1">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`flex text-left flex-1 items-center p-2 focus:outline-none cursor-pointer ${
                            open ? "" : ""
                          }`}
                          onClick={() => {
                            if (window.innerWidth >= 1024) {
                              document.querySelector("html")?.click();
                            }
                          }}
                        >
                        
                          <div className="flex-grow">
                            <span className="block text-3xl xl:text-2xl font-medium min-w-[40px] xl:min-w-[20px] cursor-pointer hover:dark:bg-neutral-700 rounded-full p-2">
                              <IoLogoInstagram/>
                            </span>
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-0 z-30 w-full min-w-[340px] 3xl:w-[400px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-4 px-4 sm:px-5 rounded-3xl shadow-xl">
                            <div className="">
                              <div className="relative flex flex-col space-y-3">
                                {Socials.map((item) => (
                                  <div key={item.id} className="flex hover:dark:bg-neutral-700 rounded-full p-2">
                                      <div className="divider"></div>
                                      <span className="flex items-center xl:text-xl">{item?.icon || '    '}<a href={item?.hrefInst || ''} target="_blank" rel="noreferrer" className="text-sm font-normal ml-2">{item.name}</a></span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  </li>
                  <li>
                   {/* <NcDropDown /> */}
                   <Popover className="flex relative flex-1">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`flex text-left flex-1 items-center p-2 focus:outline-none cursor-pointer ${
                            open ? "" : ""
                          }`}
                          onClick={() => {
                            if (window.innerWidth >= 1024) {
                              document.querySelector("html")?.click();
                            }
                          }}
                        >
                        <div className="flex-grow">
                          <span className="block text-3xl xl:text-2xl font-medium min-w-[40px] xl:min-w-[20px] cursor-pointer hover:dark:bg-neutral-700 rounded-full p-2">
                            <IoLogoGithub />
                          </span>
                     
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-0 z-30 w-full min-w-[320px] 3xl:w-[400px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-4 px-4 sm:px-5 rounded-3xl shadow-xl">
                          <div className="">
                            <div className="relative flex flex-col space-y-3">
                              {Socials.map((item) => (
                                <div key={item.id} className="flex hover:dark:bg-neutral-700 rounded-full p-2">
                                    <div className="divider"></div>
                                    <span className="flex items-center xl:text-xl">{item.icon}<a href={item.hrefGit} target="_blank" rel="noreferrer" className="text-sm font-normal ml-2">{item.name}</a></span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                  </li>
                  <li>
                   {/* <NcDropDown /> */}
                   <Popover className="flex relative flex-1">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`flex text-left flex-1 items-center p-2 focus:outline-none cursor-pointer ${
                            open ? "" : ""
                          }`}
                          onClick={() => {
                            if (window.innerWidth >= 1024) {
                              document.querySelector("html")?.click();
                            }
                          }}
                        >
                        <div className="flex-grow">
                          <span className="block text-3xl xl:text-2xl font-medium min-w-[40px] xl:min-w-[20px] cursor-pointer hover:dark:bg-neutral-700 rounded-full p-2">
                            <IoLogoLinkedin  />
                          </span>
                
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-0 z-30 w-full min-w-[270px] 3xl:w-[400px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-4 px-4 sm:px-5 rounded-3xl shadow-xl">
                          <div className="">
                            <div className="relative flex flex-col space-y-3">
                              {Socials.map((item) => (
                                <div key={item.id} className="flex hover:dark:bg-neutral-700 rounded-full p-2">
                                    <div className="divider"></div>
                                    <span className="flex items-center xl:text-xl">{item.icon}<a href={item.hrefLink} target="_blank" rel="noreferrer" className="text-sm font-normal ml-2">{item.name}</a></span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                  </li>
          
                </ul>
            </div>
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">

          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <br></br>
      <hr></hr>
      <span className="block text-center text-sm dark:text-white text-neutral-700 sm:text-center w-full p-3 rounded ">
                © {getCurrentYear()}  {' '}
                <a href="https://nexgentech.cl/" className="hover:underline">
                NexGen Technologies
                </a>
                . Todos los derechos reservados.
        </span>
    </div>
  );
};

export default Footer;
